//import React, { useEffect, useState } from 'react';
import {Outlet,Navigate,useLocation } from 'react-router-dom';
import { axiosInstance } from '../api/apiConfig'
import React, { useEffect, useState } from 'react';
const PrivateRoute = ({ children}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const location = useLocation();
   useEffect(() => {
    // Define an async function to check authentication
    const checkAuthentication = async () => {
      try {
        const response = await axiosInstance.get('user/permissions');
        console.log("user is authenticated");
        setIsAuthenticated(true);
      } catch (error) {
        console.log("User is not authenticated or error occurred", error);
        setIsAuthenticated(false);
      }
    };

    checkAuthentication();
  }, []);
  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  // If authenticated, render children
  if (isAuthenticated) {
    return children;
  }

  return <Navigate to="/registration" state={{ from: location }} replace />
}
export default PrivateRoute;