import React, { useEffect, useState } from 'react';
import { axiosInstance } from '../api/apiConfig'
import config from "../config"
const BACKEND_URL = config.apiUrl
const LaunchDrone = () => {
  const [hasPermission, setHasPermission] = useState(false);
  const [queueState, setQueueState] = useState([]);
  useEffect(() => {
    // Function to fetch user permissions
    const fetchUserPermissions = async () => {
      try {
        const response = await axiosInstance.get('user/permissions');
        // Assuming response.data.permissions is an array of permissions
        const hasRequiredPermission = response.data['is_superuser'];
        setHasPermission(hasRequiredPermission);
        const token = localStorage.getItem('access_token');
        const ws = new WebSocket(`wss://${BACKEND_URL}/ws/queue_updates/?token=${token}`)
        ws.onmessage = function(event) {
            const newMessage = event.data;
//             var parsed_data = JSON.parse(JSON.parse(event.data))
            setQueueState((prevMessages) => [...prevMessages, newMessage]);
        };
        await axiosInstance.post('/get_queue', {"action":"admin_join"})
      } catch (error) {
        console.error('Error fetching user permissions:', error);
        // Handle error fetching permissions
      }
    };

    // Call the function to fetch user permissions
    fetchUserPermissions();
  }, []); // Empty dependency array means this effect runs only once

  return (
    <div>
      {hasPermission && (
        <div>
          {/* Render your widget here */}
          <h2>Conditional Widget</h2>
          <p>This widget is rendered conditionally based on permissions.</p>
          <p id="number-display">Queue:
          {queueState.map((message, index) => (
          <li key={index}>{message}</li>
        ))}</p>
        </div>
      )}
      {!hasPermission && (
        <div>
          {/* Optionally, render something else or show a message */}
          <p>You do not have permission to view this widget.</p>
        </div>
      )}
    </div>
  );
};

export default LaunchDrone;