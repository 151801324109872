import { Routes, Navigate, Route } from 'react-router-dom'
import AuthMiddleware from './middlewares/AuthMiddleware';
//import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
//import Home from './pages/Home';
//import User from './pages/auth/User'
import Launch_Drone from './pages/drone/Launch_Drone'
import Payment_Page from './pages/payment/payment_page'
import PersistLogin from './components/PersistLogin';
import Navbar from "./components/Navbar"
import PrivateRoute from './components/PrivateRoute'
import QueuesPage from './pages/drone/QueuesPage'
import CreateQueuePage from './pages/drone/CreateQeuePage'

console.log(process.env)
function App() {
  return <>
    <Navbar />
    <Routes>
        <Route path="/" element={<Launch_Drone/>} ></Route>
        <Route path='/drone'>
            <Route path='launch_drone' element={<Launch_Drone/>}></Route>
        </Route>
        <Route path="/login" element={<Register/>}></Route>
        <Route path="/protected_payment" element={
            <PrivateRoute>
            <Payment_Page/>
            </PrivateRoute>

        } />
        <Route path="/create_queue" element={
            <PrivateRoute>
            <CreateQueuePage/>
            </PrivateRoute>

        } />
        <Route path="/queues" element={<QueuesPage/>}> </Route>
        <Route path='/payment'>
            <Route path='/payment' element={<Payment_Page/>}></Route>
        </Route>
      <Route path='*' element={<Navigate to='/' />}></Route>
    </Routes>
  </>
}

export default App;
