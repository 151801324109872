import React, { useState } from 'react';
import { axiosInstance } from '../../api/apiConfig'
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('your_publishable_key');  // Replace with your public key

const CheckoutForm = ({queue}) => {
  const [stripe, setStripe] = useState(null);

  // Ensure stripe is loaded
  if (!stripe) {
    stripePromise.then((stripeInstance) => {
      setStripe(stripeInstance);
    });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axiosInstance.post(`/create-checkout-session/${queue}/`, {email:localStorage.getItem('user_email'), action:"join_queue"});
      const { url } = response.data;
      // Redirect to Stripe Checkout
      alert(url + " was the url")
      window.location.href = url;
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <button type="submit">Checkout</button>
    </form>
  );
};

export default CheckoutForm;