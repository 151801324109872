import React, { useEffect, useRef, useState } from 'react';
import { Director, View } from '@millicast/sdk';
import { axiosInstance } from '../api/apiConfig'
const MillicastWidget = () => {
  const videoRef = useRef(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(null);
  useEffect(() => {
  const getToken = async () => {
            try {
                const token_response = await axiosInstance.get('/get_token_for_player');
                if(token_response["data"]["token"] =="None"){
                    return;
                }
               var streamName = 'new_test_stream';
               setToken(token_response["data"]["token"]);
               alert(token_response["data"]["token"] + " was token in javascript");
               const tokenGenerator = async () => {
                    var succeeded = false;
                    while(!succeeded){
                        try {
                            const response = await Director.getSubscriber({
                                streamName: 'new_test_stream',
                                streamAccountId: 'ybzReA',
    //                            subscriberToken: "787b7b2f325ada3fc1887ec015db36cabb24e7f253d96fb9ae931f7ea69a63b2",
                                subscriberToken: token_response["data"]["token"]
                            });
                            succeeded = true;
                            return response;
                        } catch (error) {
                            console.error("Error fetching subscriber token:", error);
//                            millicastView.reconnect();
                            // Handle the error, e.g., return a fallback value or rethrow the error
                            return null; // Or you could rethrow the error if you want the caller to handle it
                        }
                    }
                };
                alert(JSON.stringify(token_response) + " bullshit react");
                const millicastView = new View(streamName, tokenGenerator);
                millicastView.on("track", (event) => {
                  console.log("Stream has started.");
                  videoRef.current.srcObject = event.streams[0];
                  videoRef.current.hidden = false;
                  videoRef.current.autoplay = true;
                });
                var connection_succeeded = false;
                try{
//                millicastView.connect()
                }
                catch (e) {
                    console.log("Connection failed, handle error", e);
//                    millicastView.reconnect();
                }



//                setToken("https://viewer.millicast.com?streamId=ybzReA/new_test_stream&token=" + token_response["data"]["token"]);
                console.log(token_response["data"]["token"]);
                return token_response["data"]["token"];
            } catch (error) {
                console.error('Error fetching Millicast token:', error);
            }
        };
    getToken();

  }, []);
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error initializing player: {error.message}</div>;
  }
  return (
    <div>

      {
      /*<iframe src={token} allowFullScreen width="640" height="480"></iframe>*/
      }
      {/*<video width="640"
            height="360"
            ref={videoRef}></video>*/}

    {<iframe src={`https://viewer.millicast.com?streamId=ybzReA/new_test_stream&token=${token}`} allowFullScreen width="640" height="480"></iframe>}
    </div>
  );
};

export default MillicastWidget;