import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { axiosInstance } from '../../api/apiConfig'
import {  useGoogleLogin} from '@react-oauth/google';
import GoogleLoginButton from '../../components/GoogleLoginButton';
import LaunchDrone from '../../components/launch_drone_empty_queues'
import LogoutButton from '../../components/LogoutButton';
import MillicastWidget from '../../components/MillicastWidget';
import QueuesList from '../../components/drone/QueuesList';
import { AuthProvider, useAuth } from '../../context/AuthContextOauth';
import './TwoColumn.css';
export default function QueuesPage() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const first_name = useRef()
    const last_name = useRef()
    const email = useRef()
    const password = useRef()
    const password2 = useRef(undefined)
    async function attempt_api_or_login(url, request_type){
     try{
     var response = ""
     if(request_type=="GET"){

        response = await axiosInstance.get(url);
     }
    else if(request_type=="POST"){
        response = await axiosInstance.post(url);
    }

        return response;
        }
     catch(error){

        if (error.response && (error.response.status === 403 || error.response.status === 401)) {
        // Trigger the Google login button click programmatically
        if (loginButtonRef.current) {
          loginButtonRef.current.triggerLogin(url,request_type);
            }
          }
        }
    }
    async function onSubmitForm(event) {
        event.preventDefault()
        const data = {
            first_name: first_name.current.value,
            last_name: last_name.current.value,
            email: email.current.value,
            password: password.current.value,
            password2: password2.current.value
          };

        setLoading(true)

        try {
            const response = await axiosInstance.post('auth/register', JSON.stringify(data))

            setLoading(false)

            navigate('/auth/login')
        } catch (error) {
            setLoading(false)
            // TODO: handle errors
        }
    }

    async function launch_drone(){
//         alert("in the launch drone method")
        try{
        const response = await axiosInstance.get('/fly_drone')
        }
        catch(error){

            if (error.response && error.response.status === 403) {
            // Trigger the Google login button click programmatically
            if (loginButtonRef.current) {
              loginButtonRef.current.triggerLogin();
                }
          }
        }
    }

      useEffect(() => {
      const initializeMillicast = async () => {
    try {

//       load_player()
    } catch (error) {
      console.error('Error loading Millicast SDK:', error,  error.stack);
    }};

  // Call the function to initialize Millicast
//   initializeMillicast();

  // Cleanup: remove the dynamically added script when the component unmounts
  return () => {
    const scriptElement = document.getElementById('millicast_script');
    if (scriptElement) {
      scriptElement.remove();
    }
  };
}, []);
function logout(){
    localStorage.removeItem("access_token");
}
async function call_login(codeResponse){
    const response = await axiosInstance.post('/social/login/', codeResponse)
    alert("what was the login response? " + JSON.stringify(response))
    localStorage.setItem('access_token', response["data"]["access_token"]);
}
async function join_video_queue(){
    const response = attempt_api_or_login('/join_queue', "POST")
    alert("what is the join queue response? "+ JSON.stringify(response));
    if(response.status == 200){

    }
}
const loginButtonRef = useRef(null);
const LoginLogoutWidget = () => {
  const { isLoggedIn } = useAuth();

  return (
    <div>
      {!isLoggedIn && <GoogleLoginButton ref={loginButtonRef}/>}
      {isLoggedIn && <LogoutButton />}
    </div>
  );
};
    return (
        <div className='two-columns-container'>
        <div className="column">

          <QueuesList/>

        </div>
        </div>

    )
}
