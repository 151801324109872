// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.two-columns-container {
  display: flex; /* Use flexbox to create a flexible layout */
}

.column {
  flex: 1 1; /* Each column takes up an equal amount of space */
  padding: 20px;
  border: 1px solid #ccc;
  margin: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/payment/TwoColumn.css"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAE,4CAA4C;AAC7D;;AAEA;EACE,SAAO,EAAE,kDAAkD;EAC3D,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd","sourcesContent":[".two-columns-container {\r\n  display: flex; /* Use flexbox to create a flexible layout */\r\n}\r\n\r\n.column {\r\n  flex: 1; /* Each column takes up an equal amount of space */\r\n  padding: 20px;\r\n  border: 1px solid #ccc;\r\n  margin: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
