import React, { useRef, forwardRef } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useAuth } from '../context/AuthContextOauth';
import { axiosInstance } from '../api/apiConfig'
import { useNavigate } from 'react-router-dom';
const GoogleLoginButton = forwardRef((props, ref) => {
  const { login } = useAuth();
  const buttonRef = useRef(null);
  const savedUrlRef = useRef("");
  const postType = useRef("")
  const navigate = useNavigate();

  async function call_login(codeResponse){
    const response = await axiosInstance.post('/social/login/', codeResponse);
    alert("what was the login response from button? " + JSON.stringify(response));
    localStorage.setItem('access_token', response["data"]["access_token"]);
    localStorage.setItem('user_email', response["data"]["user_email"]);
    login();
     const redirectAfterLogin = sessionStorage.getItem('redirectAfterLogin');
      sessionStorage.removeItem('redirectAfterLogin');
      if (redirectAfterLogin) {
        navigate(redirectAfterLogin); // Use navigate instead of history.push
      } else {
        navigate('/'); // Use navigate instead of history.push
      }
     if (postType.current=="POST"){
        const callback_response = await axiosInstance.post(savedUrlRef.current);
    }
    else if (postType.current=="GET"){
        const callback_response = await axiosInstance.get(savedUrlRef.current);
    }
    savedUrlRef.current = "";
    postType.current = "";
//    props.onLoginSuccess();
}


  const googleLogin = useGoogleLogin({
  onSuccess: codeResponse =>
  call_login(codeResponse),
  // call back end with auth code to get access code
  flow: 'auth-code',
});


  return (
    <button ref={buttonRef} onClick={() => googleLogin()}>
      Login with Google
    </button>
  );
});

export default GoogleLoginButton;