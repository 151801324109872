import React, { useState } from 'react';
import { axiosInstance } from '../../api/apiConfig'

const CreateQueuePage = () => {
  // State to manage form data
  const [formData, setFormData] = useState({
    name: '',
    location: '',
    description: ''
  });
  const [errors, setErrors] = useState({});
   const [successMessage, setSuccessMessage] = useState('');

  // Handle input change
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
    name: formData.name,
    location: {
      name: formData.name,
      address: formData.location,
    },
    description: formData.description
  };
    try {
      const response = await axiosInstance.post('/create_queue', data);
      setSuccessMessage(response.data.message);
      setErrors({});
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors(error.response.data);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </label>
      </div>

      <div>
        <label>
          Location:
          <input
            type="text"
            name="location"
            value={formData.location}
            onChange={handleChange}
            required
          />
        </label>
      </div>

      <div>
        <label>
          description:
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
          >
          </textarea>
        </label>
      </div>

      <button type="submit">Submit</button>
    </form>
  );
};

export default CreateQueuePage;