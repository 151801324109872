import { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import GoogleLoginButton from '../../components/GoogleLoginButton';
import LogoutButton from '../../components/LogoutButton';
import { AuthProvider, useAuth } from '../../context/AuthContextOauth';

export default function Register() {
    const navigate = useNavigate();
    const location = useLocation();  // Get the current location
    const [loading, setLoading] = useState(false);

    const loginButtonRef = useRef(null);

    const LoginLogoutWidget = () => {
        const { isLoggedIn } = useAuth();

        const handleLoginSuccess = async (response) => {
            // Handle the login success logic
            // Example: send the response to the server, save tokens, etc.

            // Assuming the login is successful, navigate back to the original page
            const redirectAfterLogin = sessionStorage.getItem('redirectAfterLogin');
              sessionStorage.removeItem('redirectAfterLogin');
              if (redirectAfterLogin) {
                navigate(redirectAfterLogin); // Use navigate instead of history.push
              } else {
                navigate('/'); // Use navigate instead of history.push
              }
        };

        const handleLoginFailure = (error) => {
            console.error('Login failed:', error);
            // Handle login failure
        };

        const login = useGoogleLogin({
            onSuccess: handleLoginSuccess,
            onFailure: handleLoginFailure,
        });

        return (
            <div>
                {!isLoggedIn && <GoogleLoginButton onLoginSuccess={handleLoginSuccess} onLoginFailure={handleLoginFailure}/>}
                {isLoggedIn && <LogoutButton />}
            </div>
        );
    };

    return (
        <div className='container'>
            <h2>Login/Logout</h2>
            <AuthProvider>
                <LoginLogoutWidget />
            </AuthProvider>
        </div>
    );
}
