import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { axiosInstance } from '../../api/apiConfig'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {PaymentElement} from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm'
import GoogleLoginButton from '../../components/GoogleLoginButton';
import LogoutButton from '../../components/LogoutButton';
import { AuthProvider, useAuth } from '../../context/AuthContext';
import './TwoColumn.css';

export default function Payment_Page() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const first_name = useRef()
    const last_name = useRef()
    const email = useRef()
    const password = useRef()
    const password2 = useRef(undefined)
    var public_stripe_key = "pk_test_51OvUxo1AeqW4WvcdvuaqFitEg7qXkbXnDv5K7FhV1rwHUSUihyuz3rXw1tz6CwIjZHPxRUGZ4zJU2UVXFfrw5e6p00LtjCPxPD"
    const stripePromise = loadStripe(public_stripe_key)
    useEffect(() => {
    }, []);

    return (
        <div className='two-columns-container'>
        <div className="column">
            <h2>Drone live stream</h2>
            <p id="number-display">Number: <span id="number"></span></p>
        <button >pay for drone flight</button>
        <br/>
         <CheckoutForm />
        <a href="https://donate.stripe.com/14k28D5684rEa6Q4gh"> Donate </a>
        </div>
        <div className="column">
            Queue stuff goes in here
        </div>
        </div>

    )
}
