import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider  } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <AuthContextProvider>
      <GoogleOAuthProvider clientId="563806495340-ltjhbfvtuogec8ofcpgnke6esbdgcq14.apps.googleusercontent.com">
        <App />
        </GoogleOAuthProvider>
      </AuthContextProvider>
    </BrowserRouter>
);
