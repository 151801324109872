import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../../api/apiConfig'
import CheckoutForm from '../../pages/payment/CheckoutForm'
const QueuesList = () => {

    const [queues, setQueues] = useState([]);
    const alert_function = async () => {
            try {
               alert("a function");
            } catch (error) {
                console.error('Error', error);
            }
        };
    const getList = async () => {
            try {
                const response = await axiosInstance.get('/get_queue_list');
                setQueues(response.data)
            } catch (error) {
                console.error('Error fetching Millicast token:', error);
            }
        };
    useEffect(() => {
        getList()
    }, []);

    return (
        <div>
            <h2>Queues List</h2>
            <ul>
                    {queues.map(queue => {
              // Convert the created_at date to local time
                  const createdAtLocal = new Date(queue.created_at).toLocaleString();
                  const endsAtLocal = new Date(queue.ends_at).toLocaleString();
                  const launchTimeLocal = new Date(queue.launch_time).toLocaleString();

                  return (
                    <li key={queue.id}>
                      <h2>{queue.location.name}</h2>
                      <p><strong>Created Date:</strong> {createdAtLocal}</p>
                      <strong>Link:</strong> <CheckoutForm queue={queue.id}>buy ticket for {queue.id}</CheckoutForm>
                      <p><strong>Ends At:</strong> {endsAtLocal}</p>
                      <p><strong>Launch Time:</strong> {launchTimeLocal}</p>
                      <p><strong>Description:</strong> {queue.description}</p>
                      <p><strong>Go to https://discord.com/channels/1276631698381082818/1276631792534818938 for chat </strong></p>
                    </li>
                  );
                })}
              </ul>
        </div>
    );
};

export default QueuesList;