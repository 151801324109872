import axios from "axios"
import config from "../config"
const API_URL = config.apiUrl

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_HOST,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json"
    }
})
console.log("the url was " + API_URL);
console.log("why the fuck is axios a piece of shit " + process.env.NODE_ENV)
axiosInstance.interceptors.request.use(
  config => {
    // Retrieve token from localStorage
    const token = localStorage.getItem('access_token');

    // If token exists, add it to the Authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  response => {
    // If the request succeeds, return the response
    return response;
  },
  error => {
    alert("login error for url " + error.config?.url + JSON.stringify(error) + JSON.stringify(error.stack))
    // If the request fails
    if (error.response && (error.response.status === 403|error.response.status === 401)) {
      sessionStorage.setItem('redirectAfterLogin', window.location.pathname);
       window.location.href = '/login';
      return Promise.reject(error)
    }
    // Return any other errors
    return Promise.reject(error);
  }
);
export {axiosInstance}
export const axiosPrivateInstance = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json"
    }
})